<template>
  <div class="customer-profiling-list-table-cell">
    <span class="customer-profiling-list-table-cell__text">
      {{ displayValue }}
    </span>
  </div>
</template>

<script>
import { get, isNil, isNaN } from 'lodash';
import { computed } from 'vue';

export default {
  props: {
    columnKey: {
      type: String,
      required: true,
    },
    field: {
      type: String,
      required: true,
    },
    customerProfile: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const displayValue = computed(() => {
      const value = get(props.customerProfile, props.field);
      const parsedValue = parseFloat(value);

      const isValidPercentage = !isNil(parsedValue) && !isNaN(parsedValue) && parsedValue > 0;
      if (!isValidPercentage) return '-';

      return `${(parsedValue * 100).toFixed(2)}%`;
    });

    return {
      displayValue,
    };
  },
};
</script>
